<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />

    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">Create Terms & Conditions</h3>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 button-bold" color="whiteColor" t-data="cancel-btn" @click="showCancelPopup">
            Cancel
          </v-btn>
          <v-btn
            class="ma-2 save-button sub-body-bold"
            t-data="save-btn"
            color="primary600Color"
            @click="save"
          >
            <v-icon left light>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
        <h6 class="mb-0 card-title">
          Terms & Conditions Information
        </h6>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-0">
        <v-row align="start" justify="start" class="mt-0">
          <v-col cols="6" class="py-0">
            <p
              class="body-regular warning-content mb-6"
              t-data="warning-content"
            >
              All fields are required.
            </p>
          </v-col>
        </v-row>
        <v-form ref="form" t-data="create-form" lazy-validation>
          <v-row align="start" justify="start" class="mt-0 mb-4">
            <v-col :cols="!isNotRequiredBranch && this.termAndConditionObject.type !== null ? 3 : 6" class="mt-0 pt-0">
              <p class="edit-field-label sub-body-bold" t-data="info-label">Type</p>
              <v-select
                t-data="info-value"
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Type"
                v-model="termAndConditionObject.type"
                :menu-props="{bottom: true, offsetY: true}"
                :items="types"
                :rules="[v => !!v || 'Type is required']"
                item-text="label"
                item-value="value"
                @input="handleTypeChange"
              ></v-select>
            </v-col>
            <v-col cols="3" class="mt-0 pt-0" v-if="!isNotRequiredBranch && this.termAndConditionObject.type !== null">
              <v-multiple-selected
                t-data="branch-selector"
                v-if="!isLoading"
                title="Branch"
                placeholder="Select Branch"
                :branch="orgObject"
                :selectedBranch="termAndConditionObject.orgs"
                :syncData="changedSelectedBranch"
                :is-disabled="orgObject.length <= 1"
                :multiple="true"
                :hide-detail="false"
                :is-changed="isChanged"
                @resetIsChanged="isChanged = false"
              ></v-multiple-selected>
            </v-col>
            <v-col cols="3">
              <p class="edit-field-label sub-body-bold" t-data="info-label">New Version
                <span class="value-message small mb-0" v-if="latestVersion">
                  (Latest Version: {{ latestVersion }})
                </span>
              </p>
              <v-text-field
                class="can-disable-selector"
                t-data="info-value"
                placeholder="Enter New Version"
                outlined
                required
                dense
                v-model="termAndConditionObject.version"
                :rules="newVersionRules"
                :disabled="disabledInputVersion"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
              <p class="edit-field-label sub-body-bold" t-data="info-label">Application Version</p>
              <v-text-field
                class="can-disable-selector"
                t-data="info-value"
                placeholder="Enter Application Version"
                outlined
                required
                dense
                v-model="termAndConditionObject.app_version"
                :rules="appVersionRules"
                :disabled="disabledInputVersion"
                ></v-text-field>
            </v-col>
          </v-row>

          <!-- Contents -->
          <v-row>
            <v-col cols="6">
              <h6 class="mb-3 card-title" t-data="info-label">Thai Description</h6>
              <quill-editor
                t-data="info-value"
                :class="{'description-error': isDesThError}"
                v-model="termAndConditionObject.contents[1].description"
                :options="editorOption"
              ></quill-editor>
              <v-row
                v-show="isDesThError"
                t-data="des-th-error"
                align="center"
                justify="start"
                class="flex-nowrap"
                no-gutters
              >
                <p class="edit-error-message value-message small mb-0">
                  Thai Description is required
                </p>
              </v-row>
            </v-col>
            <v-col cols="6">
             <h6 class="mb-3 card-title pr-3" t-data="info-label">English Description</h6>
              <quill-editor
                t-data="info-value"
                :class="{'description-error': isDesEngError}"
                v-model="termAndConditionObject.contents[0].description"
                :options="editorOption"
              ></quill-editor>
              <v-row
                v-show="isDesEngError"
                t-data="des-en-error"
                align="center"
                justify="start"
                class="flex-nowrap"
                no-gutters
              >
                <p class="edit-error-message value-message small mb-0">
                  English Description is required
                </p>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'
import PopupLoading from '../../components/PopupLoading'
import ImageUploader from 'quill-image-uploader'
import MyLink from '@/constants/quill-link'
import IndentStyle from '@/constants/quill-indent';
import {convertFileToBase64, isNewerVersion} from '@/helper/helper'
import AlertError from '@/components/AlertError'
import MultipleSelected from '@/components/MultipleSelected'
import {termConditionTypeEnum, termConditionTypeText} from '@/constants/term-condition'
import AlertConfirm from '@/components/AlertConfirm'
Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
Quill.register({ "formats/indent": IndentStyle }, true)

export default {
  components: {
    quillEditor,
    'v-multiple-selected': MultipleSelected,
    'v-alert-error': AlertError,
    'v-alert-confirm': AlertConfirm,
    'v-popup-loading': PopupLoading,
  },
  data() {
    return {
      isChanged: false,
      isError: false,
      isCancel: false,
      isDesThError: false,
      isDesEngError: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}, { 'align': [] }],
            ['link', 'image'],
            [{'indent': '-1'}, {'indent': '+1' }],
          ]
        }
      },
    }
  },
  async created() {
    await this.setOrgInfo()
  },
  computed: {
    isNotRequiredBranch() {
      return this.termAndConditionObject.type === termConditionTypeEnum.BINDING || this.termAndConditionObject.type === termConditionTypeEnum.REGISTER
    },
    types() {
      return _.map(termConditionTypeEnum, (type) => {
        return {value: type, label: termConditionTypeText[type]}
      })
    },
    disabledInputVersion() {
      const notSelectedBranch = this.termAndConditionObject.orgs === null || this.termAndConditionObject.orgs.length === 0
      if (this.isNotRequiredBranch) {
        return false
      } else {
        return notSelectedBranch || this.termAndConditionObject.type === null
      }
    },
    newVersionRules() {
      const rules = [
        v => !!v || 'New Version is required',
        v => /^\d+.\d+.\d+$/.test(v) || 'New Version is invalid format',
      ]
      if (this.latestVersion !== null) {
        rules.push(v => (!!v && isNewerVersion(this.latestVersion, v)) || `New version must be greater than ${this.latestVersion}`)
      }
      return rules
    },
    appVersionRules() {
      return [
        v => !!v || 'Application Version is required',
      ]
    },
    breadcrumbs() {
      return [
        {text: 'App Config', links: true, href: '/'},
        {text: 'Terms & Conditions', links: true, href: '/term-and-conditions'},
        {text: 'Create Terms & Conditions', links: false}
      ]
    },
    ...mapState('termAndCondition', [
      'isLoading',
      'latestVersion',
      'termAndConditionObject',
      'orgObject'
    ])
  },
  destroyed() {
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  watch: {
    termAndConditionObject: {
      handler() {
        if (this.termAndConditionObject.contents[0].description !== '') this.isDesEngError = false
        if (this.termAndConditionObject.contents[1].description !== '') this.isDesThError = false
      },
      deep: true
    },
  },
  methods: {
    validate() {
      const removeHtml  = (text) => text.replace(/<[^>]*>?/gm, '');
      const invalidEng = removeHtml(this.termAndConditionObject.contents[0].description) === ''
      const invalidTh = removeHtml(this.termAndConditionObject.contents[1].description) === ''
      if (invalidEng) {
        this.isDesEngError = true
      }
      if (invalidTh) {
        this.isDesThError = true
      }
      return !invalidEng && !invalidTh && this.$refs.form.validate();
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'term-and-conditions'})
    },
    async save() {
      this.$refs.form.validate()
      if (this.validate()) {
        const status = await this.create(this.termAndConditionObject)
        if (status) {
          this.$router.push({
            name: 'term-and-conditions',
            query: {isCreateSuccess: true, snackTitle: 'created'}
          })
        } else {
          this.isError = true
        }
      }
    },
    async handleTypeChange() {
      await this.getLatestVersion()
      if (this.isNotRequiredBranch) {
        this.termAndConditionObject.orgs = []
        this.isChanged = true
      }
      this.$refs.form.resetValidation()
    },
    async changedSelectedBranch(values) {
      this.termAndConditionObject.orgs = values
    },
    closeAlert() {
      this.isError = false
    },
    ...mapActions('termAndCondition', [
      'create',
      'getLatestVersion',
      'resetState',
      'setOrgInfo'
    ]),
    ...mapActions('packages', [
      'upload',
    ])
  }
}
</script>